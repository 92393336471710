<template>
    <div class="navbar-floating">
        <div id="password-forgot-page-content">
            <the-navbar-vertical-public
                theTitle="Mot de passe oublié ou activation de compte"
                display-btn-help
            />

            <div class="min-h-screen flex tw-w-full">

                <div class="main-content mx-auto self-center">
                    <div>
                        <div class="full-page-bg-color">
                            <div class="vx-row align-start justify-center">
                                <div class="vx-col left my-6">
                                    <h4 class="mb-4">Réinitialisation du mot de passe</h4>
                                    <div class="step-list">
                                    <span class="trait-vertical"></span>
                                        <div class="step">
                                            <span class="step-bullet">1</span>
                                            <div class="step-text">
                                                Entrez votre adresse email
                                                (celle renseignée auprès de la résidence
                                                de votre proche / majeur protégé).
                                            </div>
                                        </div>
                                        <div class="step">
                                            <span class="step-bullet">2</span>
                                            <div class="step-text">
                                                Un email vous est envoyé avec un lien.
                                            </div>
                                        </div>
                                        <div class="step">
                                            <span class="step-bullet">3</span>
                                            <div class="step-text">
                                                Cliquez sur le lien. Vous pourrez créer
                                                votre mot de passe.<br />
                                                Ce lien est valable 24h. Au delà,
                                                vous devrez faire une nouvelle demande.
                                            </div>
                                        </div>
                                        <div class="step">
                                            <span class="step-bullet">4</span>
                                            <div class="step-text">
                                                Après validation de votre mot de passe,
                                                vous pourrez vous connecter.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="vx-col right my-6 relative">
                                    <div class="p-8">
                                        <div class="vx-card__title mb-8 ">
                                            <h4>Email</h4>
                                        </div>
                                        <vs-input
                                            name="email"
                                            type="email"
                                            label-placeholder="Votre adresse email"
                                            v-model="email"
                                            v-on:keyup.enter="checkForm"
                                            class="tw-w-full mb-8"
                                            v-validate="'required'"
                                            maxlength="120"
                                        />
                                        <vs-button
                                            type="border"
                                            to="/connexion"
                                            class="px-4 tw-w-full md:tw-w-auto"
                                        >S'identifier</vs-button>
                                        <vs-button
                                            :disabled="!validateForm"
                                            @click="checkForm"
                                            class="float-right px-4 tw-w-full md:tw-w-auto mt-3 mb-8 md:tw-mt-0 md:tw-mb-0"
                                        >Envoyer</vs-button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <vs-alert
                        icon-pack="feather"
                        class="my-4 small m-t-30 h-auto"
                        color="rgb(1, 207, 232)"
                    >
                        <div class="pa-4">
                            <h4 class="titlex vs-alert--title">JE N’AI PAS REÇU DE MAIL, POURQUOI ?</h4><br>
                            <span class="small vs-alert--text">
                                La réception du mail peut se faire dans
                                les minutes qui suivent, regardez vos
                                <strong>spams</strong> au cas où. Il se
                                peut que vous n’ayez pas de compte.
                                Si le problème persiste, appelez notre
                                service client au <a href="tel:0184172373">01 84 17 23 73</a>
                                ou par mail <a href="mailto:contact@happytal.com"><strong>contact@happytal.com</strong></a>
                            </span>
                        </div>
                    </vs-alert>

                    <footer class="the-footer flex-wrap justify-between">
                        <p>
                            <span>COPYRIGHT &copy;</span>
                            <span>{{ new Date().getFullYear() }} </span>
                            <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>
                            <span class="tw-hidden sm:tw-inline-block">, Tous droits réservés</span>
                        </p>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import appsettings from '@/appsettings'
import Regexes from '@/constants/regexes'
import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'

export default {
    components: {
        TheNavbarVerticalPublic
    },
    data () {
        return {
            email: '',
            error: false
        }
    },
    computed: {
        validateForm () {
            if (this.errors.any()) {
                return false
            }
            if (!Regexes.Email.test(this.email)) {
                return false
            }
            return true
        }
    },
    methods: {
        checkForm () {
            this.$vs.loading()
            if (!this.email) {
                this.$vs.loading.close()
                this.$vs.notify({
                    text: 'Adresse email invalide',
                    color: 'alert'
                })
            }
            else if (!Regexes.Email.test(this.email)) {
                this.$vs.loading.close()
                this.$vs.notify({
                    text: 'Adresse email invalide',
                    color: 'alert'
                })
            }
            else {
                axios.post(appsettings.VUE_APP_USER_API + '/lost-password', {
                    'email': this.email
                })
                .then((response) => {
                    this.$vs.loading.close()
                    this.showInfoToast(`Si votre compte existe, un email de ré-initialisation a été envoyé.`)
                })
                this.email = ''
            }
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 5000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
$default-col-width: 480px;

#password-forgot-page-content {
    .main-content {
        width: 1300px;
        max-width: 100%;
        padding: 120px 3.4rem 0;

        @media (max-width: map-get($breakpoints, sm)) {
            padding: 120px 2.4rem 0;
        }
    }
    .vx-card {
        background: none;
        border-radius: 0;
        box-shadow: none;
        -webkit-transition: none;
        background: #F8F8F8;
    }
    .vx-col.left,
    .vx-col.right {

        width: $default-col-width;
        max-width: 100%;
        flex: 0 0 $default-col-width;

        @media (min-width: map-get($breakpoints, md)) {
            flex: 1;
            min-width: $default-col-width;
            width: auto;
        }
    }
    .vx-col.left {
        background: #F8F8F8;
    }

    .vx-col.right {
        background: #fff;
        border-radius: .5rem;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    }
    .trait-vertical {
        border-left: 2px solid rgba(var(--vs-primary), 1);
        padding-left: 20px;
        position: absolute;
        left: 15px;
        height: 100%;
        z-index: 20;
    }

    .step-list {
        margin-top: 20px;
        position: relative;

        .step {
            display: flex;
            align-items: flex-start;
            margin: 20px 0px 0px 0px;

            .step-bullet {
            display: inline-block;
            flex-shrink: 0;
            background-color: rgba(var(--vs-primary), 1);
            width: 32px;
            height: 32px;
            border-radius: 16px;
            color: #fff;
            text-align: center;
            padding: 6px;
            font-size: 14px;
            z-index: 30;
            }

            .step-text {
            margin: 5px 0px 0px 10px;
            font-size: 14px;
            }
        }
    }

    .m-t-30 {
        margin-top: 40px !important;
    }
    .vs-alert span, .vs-alert h4 {
        font-size: 13px;
    }
    .vs-alert {
        padding: 8px 10px;
    }
    .vs-alert span {
        font-size: 13px;
    }
    .vx-row {
        background: #F8F8F8;
    }
    .vs-alert--title {
        font-size: .9rem;
        font-weight: 700;
        padding: 0px;
    }
    .logo-login img {
        max-width: 263px !important;
        width: 263px !important;
        margin: 65px auto;
    }

    footer {
        margin-top: 40px;
        p {
            margin: 0 auto;
            display: block;
            text-align: center;
        }
        a {
            color: rgba(var(--vs-primary), 1);
        }
    }
    a {
        color: rgb(1, 207, 232);
    }
    .relative{
        position: relative;
    }
    @media (min-width: 575px) {
       .position{
            position: absolute;
            top: 20px;
            right: 20px;
       }
    }
    @media (max-width: 575px) {
       .position{
            position: relative;
            top: 12px;
            left: 20px;
    }
    }

}
</style>
